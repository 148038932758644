import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import NavbarMain from './navbarmain'
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';

import 'bootstrap/dist/css/bootstrap.min.css';


// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import 'swiper/swiper-bundle.css';

// Install the Autoplay and Pagination module'
// import required modules
import { Autoplay, Pagination } from 'swiper/modules';
// import { Footer } from './Footer';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { faArrowRight, faArrowLeft, faArrowUpRightFromSquare  } from '@fortawesome/free-solid-svg-icons';

import img1 from './Homepage/Images/blog-img-1.png'
import img2 from './Homepage/Images/blog-img-2.png'
import img3 from './Homepage/Images/mini-pro.png'

import React, { useRef, useState, useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Nav from 'react-bootstrap/Nav';
import "react-rangeslider/lib/index.css";
import  Footer  from './footer';





export const BlogDetailView = (props) => {
  const {
    categoryListData,
    count,
    setCount,
    wishlist,
    setwishlist,
    setshowFooter,
  } = props;
  return (
    <div>
      <NavbarMain categoryListData={categoryListData} setCount={setCount} count={count} setwishlist={setwishlist} wishlist={wishlist} />

<div className='container blogdetail '>
    <div className='container'>
<div className='blogcon1-sub mt-3 mb-4'>
            <p>Brass</p>
            <p>Handicrafts</p>
            <p>God Statues</p>
          </div>

          <h2>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed doeiusmod tempor incididunt </h2>
<span>Gopinath, 1 Jan 2024</span>
<img src={img3} />

<p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor </p>
<p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor </p>
<p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor </p>

<div className='blogaddbanner'>
    <p>“ Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim ”</p>
</div>

<img src={img1} />
<p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor </p>
<p>Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor </p>
          </div>

</div>

<h2 class="blogheadings ps-4 container mt-5">Related blog posts</h2>
    <div className="flex flex-wrap container">
      <div className="w-full md:w-1/3 p-4">
        <div className="text-center">
          <img src={img3} style={{height:'200px' ,width:'100%'}} alt="Image 1" className="mx-auto mb-4 blogimage" />
          <div class="blogcon1">
          <p>Gopinath • 1 Jan 2024</p>
          <h2 className='flex justify-between'>Blog Title Here <FontAwesomeIcon icon={faArrowUpRightFromSquare} size='2xs' style={{color: "#000000",}} /></h2>
          <p className='blogcon1-content mt-2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
          <div className='blogcon1-sub mt-5'>
            <p>Brass</p>
            <p>Handicrafts</p>
            <p>God Statues</p>
          </div>
        </div>
        </div>
      </div>
      <div className="w-full md:w-1/3 p-4">
        <div className="text-center">
          <img src={img3} style={{height:'200px' ,width:'100%'}} alt="Image 1" className="mx-auto mb-4 blogimage" />
          <div class="blogcon1">
          <p>Gopinath • 1 Jan 2024</p>
          <h2 className='flex justify-between'>Blog Title Here <FontAwesomeIcon icon={faArrowUpRightFromSquare} size='2xs' style={{color: "#000000",}} /></h2>
          <p className='blogcon1-content mt-2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
          <div className='blogcon1-sub mt-5'>
            <p>Brass</p>
            <p>Handicrafts</p>
            <p>God Statues</p>
          </div>
        </div>
        </div>
      </div>
      <div className="w-full md:w-1/3 p-4">
        <div className="text-center">
          <img src={img3} style={{height:'200px' ,width:'100%'}} alt="Image 1" className="mx-auto mb-4 blogimage" />
          <div class="blogcon1">
          <p>Gopinath • 1 Jan 2024</p>
          <h2 className='flex justify-between'>Blog Title Here <FontAwesomeIcon icon={faArrowUpRightFromSquare} size='2xs' style={{color: "#000000",}} /></h2>
          <p className='blogcon1-content mt-2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
          <div className='blogcon1-sub mt-5'>
            <p>Brass</p>
            <p>Handicrafts</p>
            <p>God Statues</p>
          </div>
        </div>
        </div>
      </div>
    </div>
<center>
    <button className='blogbtn'>Load more</button>
    </center>
<Footer/>
    </div>
  )
}
