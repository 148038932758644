import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import NavbarMain from './navbarmain'
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';

import 'bootstrap/dist/css/bootstrap.min.css';


// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import 'swiper/swiper-bundle.css';

// Install the Autoplay and Pagination module'
// import required modules
import { Autoplay, Pagination } from 'swiper/modules';
// import { Footer } from './Footer';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { faArrowRight, faArrowLeft, faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';

import img1 from './Homepage/Images/blog-img-1.png'
import img2 from './Homepage/Images/blog-img-2.png'
import img3 from './Homepage/Images/mini-pro.png'

import React, { useRef, useState, useEffect } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Nav from 'react-bootstrap/Nav';
import "react-rangeslider/lib/index.css";
import  Footer  from './footer';





export const Blogpage = (props) => {
  const {
    categoryListData,
    count,
    setCount,
    wishlist,
    setwishlist,
    setshowFooter,
  } = props;
  return (
    <div>
      <NavbarMain categoryListData={categoryListData} setCount={setCount} count={count} setwishlist={setwishlist} wishlist={wishlist} />

      <section>
        <div className='container'>
          <h2 class="ps-4 blogheadings mt-4" >Recent blog posts</h2>
          <div class="flex flex-wrap">

            <div class="w-full md:w-1/2">
              <div class=" pt-4 p-4">
                <a href='/blogdetailview'><div class=" mb-4 md:mb-0 md:pr-2 blogban1">

                </div></a>

                <div class="blogcon1">
                  <p>Gopinath • 1 Jan 2024</p>
                  <h2 className='flex justify-between'>Blog Title Here <FontAwesomeIcon icon={faArrowUpRightFromSquare} size='2xs' style={{ color: "#000000", }} /></h2>
                  <p className='blogcon1-content mt-2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                  <div className='blogcon1-sub mt-5'>
                    <p>Brass</p>
                    <p>Handicrafts</p>
                    <p>God Statues</p>
                  </div>
                </div>


              </div>
            </div>
            {/* <!-- Second Column --> */}
            <div class="w-full md:w-1/2">
              <div class=" p-4 ">

                {/* <!-- First Row --> */}
                <div class="flex flex-wrap items-center mb-4">
                  {/* <!-- Left Side Image --> */}
                  <div class="w-full md:w-1/2 mb-4 md:mb-0 md:pr-2 blogban2">
                    {/* <img src="image-url.jpg" alt="Image" class="w-full h-auto md:w-350"/> */}
                  </div>
                  {/* <!-- Right Side Content --> */}
                  <div class="w-full md:w-1/2 md:pl-2 ps-3 blogcon2">
                    <p>Gopinath • 1 Jan 2024</p>
                    <h2>Blog Title Here</h2>
                    <p className='blogcon1-content'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod dolore magna aliqua. Ut enim ad minim veniam.</p>
                    <div className='blogcon1-sub mt-3 mb-4'>
                      <p>Brass</p>
                      <p>Handicrafts</p>
                    </div>
                  </div>
                </div>

                {/* <!-- Second Row --> */}
                <div class="flex flex-wrap items-center mb-4">
                  {/* <!-- Left Side Image --> */}
                  <div class="w-full md:w-1/2 mb-4 md:mb-0 md:pr-2 blogban2">
                    {/* <img src="image-url.jpg" alt="Image" class="w-full h-auto md:w-350"/> */}
                  </div>
                  {/* <!-- Right Side Content --> */}
                  <div class="w-full md:w-1/2 md:pl-2 ps-3 blogcon2">
                    <p>Gopinath • 1 Jan 2024</p>
                    <h2>Blog Title Here</h2>
                    <p className='blogcon1-content'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod dolore magna aliqua. Ut enim ad minim veniam.</p>
                    <div className='blogcon2-sub mt-3 mb-4'>
                      <p>Brass</p>
                      <p>Handicrafts</p>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <div className="flex flex-wrap mb-4 p-4">
            {/* Left Column (50% width on desktop, full width on mobile) */}
            <div className="w-full md:w-1/2 order-2 md:order-1">
              <div className="p-4">
                <div className="blogcon1">
                  <p>Gopinath • 1 Jan 2024</p>
                  <h2 className="flex justify-between">
                    Blog Title Here
                    <FontAwesomeIcon icon={faArrowUpRightFromSquare} size="2xs" style={{ color: "#000000" }} />
                  </h2>
                  <p className="blogcon1-content mt-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                  <div className="blogcon1-sub mt-5">
                    <p>Brass</p>
                    <p>Handicrafts</p>
                    {/* <p>God Statues</p> */}
                  </div>
                </div>
              </div>
            </div>
            {/* Right Column (50% width on desktop, full width on mobile) */}
            <div className="w-full md:w-1/2 order-1 md:order-2 thiruvallu">
              {/* Image goes here */}
            </div>
          </div>

          <h2 class="blogheadings ps-4 ">All blog posts</h2>
          <div className="flex flex-wrap">
            <div className="w-full md:w-1/3 p-4">
              <div className="text-center">
                <img src={img3} style={{ height: '200px', width: '100%' }} alt="Image 1" className="mx-auto mb-4 blogimage" />
                <div class="blogcon1">
                  <p>Gopinath • 1 Jan 2024</p>
                  <h2 className='flex justify-between'>Blog Title Here <FontAwesomeIcon icon={faArrowUpRightFromSquare} size='2xs' style={{ color: "#000000", }} /></h2>
                  <p className='blogcon1-content mt-2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                  <div className='blogcon1-sub mt-5'>
                    <p>Brass</p>
                    <p>Handicrafts</p>
                    <p>God Statues</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/3 p-4">
              <div className="text-center">
                <img src={img3} style={{ height: '200px', width: '100%' }} alt="Image 1" className="mx-auto mb-4 blogimage" />
                <div class="blogcon1">
                  <p>Gopinath • 1 Jan 2024</p>
                  <h2 className='flex justify-between'>Blog Title Here <FontAwesomeIcon icon={faArrowUpRightFromSquare} size='2xs' style={{ color: "#000000", }} /></h2>
                  <p className='blogcon1-content mt-2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                  <div className='blogcon2-sub mt-5'>
                    <p>Brass</p>
                    <p>Handicrafts</p>
                    <p>God Statues</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/3 p-4">
              <div className="text-center">
                <img src={img3} style={{ height: '200px', width: '100%' }} alt="Image 1" className="mx-auto mb-4 blogimage" />
                <div class="blogcon1">
                  <p>Gopinath • 1 Jan 2024</p>
                  <h2 className='flex justify-between'>Blog Title Here <FontAwesomeIcon icon={faArrowUpRightFromSquare} size='2xs' style={{ color: "#000000", }} /></h2>
                  <p className='blogcon1-content mt-2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                  <div className='blogcon1-sub mt-5'>
                    <p>Brass</p>
                    <p>Handicrafts</p>
                    <p>God Statues</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap">
            <div className="w-full md:w-1/3 p-4">
              <div className="text-center">
                <img src={img3} style={{ height: '200px', width: '100%' }} alt="Image 1" className="mx-auto mb-4 img img-fluid" />
                <div class="blogcon1">
                  <p>Gopinath • 1 Jan 2024</p>
                  <h2 className='flex justify-between'>Blog Title Here <FontAwesomeIcon icon={faArrowUpRightFromSquare} size='2xs' style={{ color: "#000000", }} /></h2>
                  <p className='blogcon1-content mt-2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                  <div className='blogcon1-sub mt-5'>
                    <p>Brass</p>
                    <p>Handicrafts</p>
                    <p>God Statues</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/3 p-4">
              <div className="text-center">
                <img src={img3} style={{ height: '200px', width: '100%' }} alt="Image 1" className="mx-auto mb-4 blogimage" />
                <div class="blogcon1">
                  <p>Gopinath • 1 Jan 2024</p>
                  <h2 className='flex justify-between'>Blog Title Here <FontAwesomeIcon icon={faArrowUpRightFromSquare} size='2xs' style={{ color: "#000000", }} /></h2>
                  <p className='blogcon1-content mt-2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                  <div className='blogcon2-sub mt-5'>
                    <p>Brass</p>
                    <p>Handicrafts</p>
                    <p>God Statues</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/3 p-4">
              <div className="text-center">
                <img src={img3} style={{ height: '200px', width: '100%' }} alt="Image 1" className="mx-auto mb-4 blogimage" />
                <div class="blogcon1">
                  <p>Gopinath • 1 Jan 2024</p>
                  <h2 className='flex justify-between'>Blog Title Here <FontAwesomeIcon icon={faArrowUpRightFromSquare} size='2xs' style={{ color: "#000000", }} /></h2>
                  <p className='blogcon1-content mt-2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                  <div className='blogcon1-sub mt-5'>
                    <p>Brass</p>
                    <p>Handicrafts</p>
                    <p>God Statues</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr></hr>
          <div></div>

          <div>
            <div className='nextpage mb-3'>
              <a style={{
                fontFamily: 'Inter',
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '20px',
                textAlign: 'left',
                color: '#c11574',
              }} > <FontAwesomeIcon icon={faArrowLeft} size="sm" style={{ color: "#c11574", }} /> Previous</a>
              <nav className=" nextpage1  rounded-md " aria-label="Pagination">

                {/* Current: "z-10 bg-indigo-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600", Default: "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0" */}
                <a
                  href="#"
                  aria-current="page"
                  className=""
                >
                  1
                </a>
                <a
                  href="#"
                  className=""
                >
                  2
                </a>
                <a
                  href="#"
                  className=""
                >
                  3
                </a>
                <span className="relative inline-flex items-center px-4 py-2 text-sm font-semibold  ">
                  ...
                </span>
                <a
                  href="#"
                  className=""
                >
                  8
                </a>
                <a
                  href="#"
                  className=""
                >
                  9
                </a>
                <a
                  href="#"
                  className=""
                >
                  10
                </a>

              </nav>
              <a style={{
                fontFamily: 'Inter',
                fontSize: '14px',
                fontWeight: 500,
                lineHeight: '20px',
                textAlign: 'left',
                color: '#c11574',
              }} > Next <FontAwesomeIcon icon={faArrowRight} size="sm" style={{ color: "#c11574", }} /> </a>
            </div>
          </div>
        </div>
      </section>


      <Footer />

    </div>
  )
}
